// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("/opt/build/repo/src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-chemical-division-js": () => import("/opt/build/repo/src/pages/chemicalDivision.js" /* webpackChunkName: "component---src-pages-chemical-division-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-enquiry-js": () => import("/opt/build/repo/src/pages/enquiry.js" /* webpackChunkName: "component---src-pages-enquiry-js" */),
  "component---src-pages-gallery-js": () => import("/opt/build/repo/src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-primer-js": () => import("/opt/build/repo/src/pages/primer.js" /* webpackChunkName: "component---src-pages-primer-js" */),
  "component---src-pages-products-js": () => import("/opt/build/repo/src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-construction-care-page-js": () => import("/opt/build/repo/src/pages/products/constructionCarePage.js" /* webpackChunkName: "component---src-pages-products-construction-care-page-js" */),
  "component---src-pages-products-decorative-paints-page-js": () => import("/opt/build/repo/src/pages/products/decorativePaintsPage.js" /* webpackChunkName: "component---src-pages-products-decorative-paints-page-js" */),
  "component---src-pages-products-enamels-js": () => import("/opt/build/repo/src/pages/products/enamels.js" /* webpackChunkName: "component---src-pages-products-enamels-js" */),
  "component---src-pages-products-epoxy-formulations-page-js": () => import("/opt/build/repo/src/pages/products/epoxyFormulationsPage.js" /* webpackChunkName: "component---src-pages-products-epoxy-formulations-page-js" */),
  "component---src-pages-products-epoxy-paints-js": () => import("/opt/build/repo/src/pages/products/epoxyPaints.js" /* webpackChunkName: "component---src-pages-products-epoxy-paints-js" */),
  "component---src-pages-products-industrial-paints-page-js": () => import("/opt/build/repo/src/pages/products/industrialPaintsPage.js" /* webpackChunkName: "component---src-pages-products-industrial-paints-page-js" */),
  "component---src-pages-products-iso-coat-js": () => import("/opt/build/repo/src/pages/products/isoCoat.js" /* webpackChunkName: "component---src-pages-products-iso-coat-js" */),
  "component---src-pages-products-iso-phobe-js": () => import("/opt/build/repo/src/pages/products/isoPhobe.js" /* webpackChunkName: "component---src-pages-products-iso-phobe-js" */),
  "component---src-pages-products-nc-paints-js": () => import("/opt/build/repo/src/pages/products/ncPaints.js" /* webpackChunkName: "component---src-pages-products-nc-paints-js" */),
  "component---src-pages-products-primer-js": () => import("/opt/build/repo/src/pages/products/primer.js" /* webpackChunkName: "component---src-pages-products-primer-js" */),
  "component---src-pages-products-pu-paints-js": () => import("/opt/build/repo/src/pages/products/puPaints.js" /* webpackChunkName: "component---src-pages-products-pu-paints-js" */),
  "component---src-pages-products-quick-dry-js": () => import("/opt/build/repo/src/pages/products/quickDry.js" /* webpackChunkName: "component---src-pages-products-quick-dry-js" */),
  "component---src-pages-products-stoving-enamels-js": () => import("/opt/build/repo/src/pages/products/stovingEnamels.js" /* webpackChunkName: "component---src-pages-products-stoving-enamels-js" */),
  "component---src-pages-products-synthetic-enamels-js": () => import("/opt/build/repo/src/pages/products/syntheticEnamels.js" /* webpackChunkName: "component---src-pages-products-synthetic-enamels-js" */),
  "component---src-pages-products-thinner-js": () => import("/opt/build/repo/src/pages/products/thinner.js" /* webpackChunkName: "component---src-pages-products-thinner-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

